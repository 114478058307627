var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"`modal-card-head`"},[_c('div',{staticClass:"container"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('header',{class:`modal-card-head`},[_c('p',{staticClass:"modal-card-title has-text-white"},[_vm._v(" "+_vm._s(_vm.$t('employes.employe'))+" ")])]),_c('section',{staticClass:"modal-card-body has-text-white",staticStyle:{"width":"500px"}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|minmax:9,9","name":"Cedula","type":"number"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Numero de cedula","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{staticClass:"has-text-black",attrs:{"maxlength":"9","type":"number"},nativeOn:{"keypress":function($event){return _vm.onlyNumbers($event)}},model:{value:(_vm.employed.identity_card),callback:function ($$v) {_vm.$set(_vm.employed, "identity_card", $$v)},expression:"employed.identity_card"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:1,20","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Nombre","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{staticClass:"has-text-black",attrs:{"maxlength":"20"},model:{value:(_vm.employed.first_name),callback:function ($$v) {_vm.$set(_vm.employed, "first_name", $$v)},expression:"employed.first_name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:1,60","name":"Primer apellido"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Primer apellido","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{staticClass:"has-text-black",attrs:{"maxlength":"60"},model:{value:(_vm.employed.middle_name),callback:function ($$v) {_vm.$set(_vm.employed, "middle_name", $$v)},expression:"employed.middle_name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:1,60","name":"Segundo apellido"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Segundo apellido","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{staticClass:"has-text-black",attrs:{"maxlength":"60"},model:{value:(_vm.employed.last_name),callback:function ($$v) {_vm.$set(_vm.employed, "last_name", $$v)},expression:"employed.last_name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required|email|minmax:1,60","name":"Correo"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Correo","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{staticClass:"has-text-black",attrs:{"maxlength":"60"},model:{value:(_vm.employed.email),callback:function ($$v) {_vm.$set(_vm.employed, "email", $$v)},expression:"employed.email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Departamento"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{staticStyle:{"color":"black"},attrs:{"label":_vm.$t('department.Name'),"type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-select',{attrs:{"expanded":"","loading":_vm.isLoading,"placeholder":"Seleccione un departamento"},model:{value:(_vm.employed.department_id),callback:function ($$v) {_vm.$set(_vm.employed, "department_id", $$v)},expression:"employed.department_id"}},_vm._l((_vm.departments),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',[_c('label',{staticClass:"checkbox has-text-black"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.employed.allow_cms_access),expression:"employed.allow_cms_access"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.employed.allow_cms_access)?_vm._i(_vm.employed.allow_cms_access,null)>-1:(_vm.employed.allow_cms_access)},on:{"change":function($event){var $$a=_vm.employed.allow_cms_access,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.employed, "allow_cms_access", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.employed, "allow_cms_access", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.employed, "allow_cms_access", $$c)}}}}),_vm._v(" Acceso al CMS ")])])],1),_c('div',{staticClass:"column is-6"},[(_vm.employed.allow_cms_access)?_c('ValidationProvider',{attrs:{"rules":"required|minmax:1,30","name":"Nombre usuario"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Nombre de usuario","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"column is-6"}),_c('div',{staticClass:"column is-6"},[(_vm.employed.allow_cms_access)?_c('ValidationProvider',{attrs:{"rules":"required|password_validate","name":"Contraseña"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Contraseña","type":{
                  'is-danger': errors[0],
                  'is-success': valid,
                },"message":errors}},[_c('b-input',{model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)]}}],null,true)}):_vm._e()],1),_c('br')])]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"level",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"level-left is-align-items-start"}),_c('div',{staticClass:"level is-justify-content-space-between",staticStyle:{"height":"40px"}},[_c('button',{staticClass:"button co-status-blue m-6",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('div',{},[_c('b-button',{class:`is-secondary has-text-white is-primary`,attrs:{"loading":_vm.isLoading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")])],1)])])])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }