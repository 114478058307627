import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Employed, IItem, User } from '@/Models'
import { Company } from '@/Models/Company'
import { Department } from '@/Models/Department'
import { onlyNumbers } from '@/helpers'
//import multiSelect from "@/components/multiSelect.vue"

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    //multiSelect,
  },
})
export default class EmployedModal extends Vue {
  @Prop({ required: false })
  employedReplace!: Employed
  @Prop({ required: false })
  action!: string

  loading = false

  employed: Employed = new Employed()
  user: User = new User()
  departments: Array<IItem> = []
  isLoading = false

  mounted() {
    console.log(this.employedReplace, 'DGFGG')
    this.getEmployee()
    if (this.action == 'edit') {
      Object.assign(this.employed, this.employedReplace)
      this.employed.department_id = this.employedReplace.department
        ? this.employedReplace.department.id
        : null
      this.employed.user_id = this.employedReplace.user
        ? this.employedReplace.user.id
        : null
      this.user = this.employedReplace.user
        ? this.employedReplace.user
        : new User()
    } else {
      this.employed = new Employed()
      this.user = new User()
    }
  }

  onlyNumbers(e: any) {
    onlyNumbers(e)
  }

  confirmation() {
    this.isLoading = true
    this.employed.user = this.user
    if (this.action == 'edit') {
      this.updateEmployed(this.employed)
    } else {
      this.createEmployed(this.employed)
    }
  }

  async createEmployed(employed: any) {
    try {
      await http.post('panel/employee/', employed)
      this.$buefy.toast.open({
        message: 'Colaborador agregado',
        type: 'is-success',
      })
      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  async getEmployee() {
    try {
      this.loading = true
      const res = await http.get('panel/department/')

      res.data.forEach((element: Department) => {
        this.departments.push({
          value: element.id,
          text: element.name,
        })
      })
      this.loading = false
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  async updateEmployed(employed: any) {
    try {
      await http.put(`panel/employee/${employed.id}/`, employed)
      this.$buefy.toast.open({
        message: 'Colaborador actualizado',
        type: 'is-success',
      })

      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }

  close() {
    this.$emit('closeForm')
  }
}
